<template>
  <VueDraggable ref="el" v-model="list" @end="onDragEnd">
    <div v-for="lesson in list" :key="lesson.id">
      <div :id="`${lesson.id}`" class="card position-relative border border-light-subtle rounded mb-3 bg-white">
        <div class="row g-0">
          <div class="col-2 py-1 mb-md-0 p-md-3">
            <div class="badge rounded-pill text-bg-secondary text-wrap position-absolute top-0 start-0 m-2">
              {{ lesson.position }}
            </div>
            <img v-if="lesson.image" :src="lesson.image" class="img-fluid rounded" />
            <video v-else-if="lesson.video" :src="lesson.video" class="img-fluid rounded" />
            <img v-else src="https://via.placeholder.com/400x240" class="img-fluid rounded" />
          </div>
          <div class="col-8 p-md-3">
            <div class="d-flex align-items-center">
              <h6 class="mt-0">
                <a :href="`/admin/lessons/${lesson.id}`" class="text-black text-decoration-none">{{ lesson.title }}</a>
              </h6>
            </div>
            <div class="text-body-secondary" style="font-size: 0.8rem;">{{ stripHtml(lesson.description.body) }}</div>
            <p class="text-body-tertiary fw-bold my-1" style="font-size: 0.9rem;">{{ formatDate(lesson.created_at) }}</p>
            <ul class="list-inline">
              <li class="list-inline-item me-3" style="font-size: 0.8rem;"><div v-html="lessonPaidStatus(lesson)"></div></li>
              <li class="list-inline-item me-3" style="font-size: 0.8rem;"><i class="bi bi-chat-dots-fill text-body-secondary me-1"></i></li>
            </ul>
          </div>
          <div class="col-2 d-flex justify-content-center align-items-center">
            <div class="dropdown">
              <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-gear-fill"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><button @click="editLesson(lesson)" class="dropdown-item">修改</button></li>
                <li><button @click="removeLesson(lesson)" class="dropdown-item">移除</button></li>
                <li><button @click="deleteLesson(lesson)" class="dropdown-item">删除</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueDraggable>
</template>

<script>
import { VueDraggable } from 'vue-draggable-plus';

export default {
  name: 'AdminCourseLesson',
  components: {
    VueDraggable,
  },
  props: {
    lessons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      list: this.lessons,
    };
  },
  methods: {
    async onDragEnd(event) {
      this.list.forEach((lesson, index) => {
        lesson.position = index + 1;
      });

      const movedItem = this.list[event.newIndex];
      const courseId = movedItem.course_id
      const lessonId = movedItem.id
      const newIndex = event.newIndex;

      fetch(`/admin/courses/${courseId}/lessons/${lessonId}/move`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({ position: newIndex, id: lessonId }),
      });
    },
    stripHtml(content) {
      return content.replace(/<[^>]+>/g, '');
    },
    lessonPaidStatus(lesson) {
      return lesson.paid ? "<span class='badge text-bg-success fw-light'>Pro</span>" : "<span class='badge text-bg-info fw-light'>Free</span>";
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    editLesson(lesson) {
      window.location.href = `/admin/lessons/${lesson.id}/edit`;
    },
    removeLesson(lesson) {
      if (confirm("Are you sure you want to remove this lesson?")) {
        fetch(`/admin/lessons/${lesson.id}/remove`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          },
        }).then(response => {
          if (response.ok) {
           response.json().then(() => {
             this.list = this.list.filter(item => item.id !== lesson.id);
             console.log('List updated:', this.list);
           });
         }
        });
      }
    },
    deleteLesson(lesson) {

    },
  },
};
</script>

